<template>
  <div class="custom-input">
    <v-menu
      ref="datePickerMenu"
      v-model="datePickerOpen"
      transition="scale-transition"
      offset-y
      min-width="auto"
      :close-on-content-click="false"
      :return-value.sync="date"
      :disabled="disabled"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          type="text"
          outlined
          color="primary accent-4"
          :append-icon="icon.mdiCalendarMinus"
          :label="label"
          :value="dateFormat()"
          :dense="dense"
          :disabled="disabled"
          readonly
          :hide-details="hideDetails"
          :placeholder="placeholder"
          :class="inputClass"
          autocomplete="new-password"
          persistent-placeholder
          :rules="rules"
          v-bind="attrs"
          v-on="on"
          @click:clear="updateFieldValueFromAction(true)"
          :clearable="!disabledClear"
        ></v-text-field>
      </template>
      <v-date-picker
        no-title
        scrollable
        v-model="date"
        :first-day-of-week="0"
        :day-format="$Formatter.dayFormat"
        locale="zh-HK"
        @input="updateFieldValue($event)"
        :type="isYearMonthOnly ? 'month' : 'date'"
        :range="isDateRange"
        :readonly="isReadOnly"
        :min="dateMin"
        :max="dateMax"
      >
        <template v-if="!disabledActionButtons">
          <v-spacer></v-spacer>
          <div class="d-flex justify-space-between" style="width: 100%;">
            <v-btn min-width="max-content" depressed small text color="error" @click="datePickerOpen = false">
              <v-icon class="mr-1" small> {{ icon.mdiClose }} </v-icon>取消
            </v-btn>
            <v-btn
              min-width="max-content"
              depressed
              small
              text
              color="primary"
              @click="updateFieldValueFromAction(false)"
            >
              <v-icon class="mr-1" small> {{ icon.mdiCheck }} </v-icon>確認
            </v-btn>
          </div>
        </template>
      </v-date-picker>
    </v-menu>
  </div>
</template>

<script>
import { mdiCalendarMinus, mdiClose, mdiCheck } from '@mdi/js'

export default {
  name: 'FormDatePicker',
  props: {
    label: {
      type: String,
      required: false,
      default: '',
    },
    fieldValue: {
      // YYYY-mm-dd | YYYY-mm
      type: String | Array,
      required: true,
      default: '',
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideDetails: {
      type: Boolean,
      required: false,
      default: false,
    },
    placeholder: {
      type: String,
      required: false,
      default: '',
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    dense: {
      type: Boolean,
      required: false,
      default: true,
    },
    isYearMonthOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
    isDateRange: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabledActionButtons: {
      type: Boolean,
      required: false,
      default: false,
    },
    disablePast: {
      type: Boolean,
      required: false,
      default: false,
    },
    disableFuture: {
      type: Boolean,
      required: false,
      default: false,
    },
    customInputClass: {
      type: String,
      required: false,
      default: '',
    },
    minDate: {
      type: String, // YYYY-MM-DD
      default: '',
    },
    maxDate: {
      type: String, // YYYY-MM-DD
      default: '',
    },
    customRules: {
      type: Array,
      required: false,
      default: () => [],
    },
    dispatchUpdateOnChange: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabledClear: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    rules() {
      return [
        ...this.customRules,
        v => {
          return this.required && this.isDateRange
            ? (this.$validate.DataValid(this.date[0]) && this.$validate.DataValid(this.date[1])) || '請選擇日期範圍'
            : true
        },
        v => {
          return this.required ? this.$validate.required(v) || '此位置不能留空' : true
        },
      ]
    },
    dateMin() {
      if (this.$validate.DataValid(this.minDate)) {
        return this.minDate
      } else if (this.disablePast) {
        return this.$Formatter.formatDate(new Date())
      }

      return undefined
    },
    dateMax() {
      if (this.$validate.DataValid(this.maxDate)) {
        return this.maxDate
      } else if (this.disableFuture) {
        return this.$Formatter.formatDate(new Date())
      }

      return undefined
    },
    isReadOnly() {
      return this.readonly || this.$store.getters.isLoading
    },
    inputClass() {
      let classObj = {
        'pointer-none': this.$store.getters.isLoading || this.disabled || this.readonly,
      }
      if (this.$validate.DataValid(this.customInputClass)) {
        const arr = this.customInputClass.split(' ')
        arr.forEach(name => {
          if (this.$validate.DataValid(name.trim())) {
            classObj[name.trim()] = true
          }
        })
      }

      return classObj
    },
  },
  data: () => ({
    icon: {
      mdiCalendarMinus,
      mdiClose,
      mdiCheck,
    },
    datePickerOpen: false,
    date: null,
  }),
  watch: {
    fieldValue: {
      handler(val) {
        this.date = val
      },
      immediate: true,
    },
  },
  methods: {
    updateFieldValue(val) {
      this.$emit('date-clicked', val)
      if (this.disabledActionButtons) {
        this.updateFieldValueFromAction()
      }
    },
    updateFieldValueFromAction(clear) {
      if (clear === true) {
        this.date = this.isDateRange ? [] : ''
      }

      let _date = this.date
      if (this.isDateRange) {
        if (this.date.length === 2 && new Date(this.date[0]).getTime() > new Date(this.date[1]).getTime()) {
          _date = [this.date[1], this.date[0]]
        }
      }

      this.$refs.datePickerMenu.save(_date)
      this.$emit('update:fieldValue', _date)
      this.$emit('changed', _date)
      if (this.dispatchUpdateOnChange === true) {
        this.$store.dispatch('setDataIsUpdated', true)
      }
      this.datePickerOpen = false
    },
    dateFormat() {
      if (!this.$validate.DataValid(this.date)) {
        return ''
      }

      if (this.isDateRange) {
        if (Array.isArray(this.date)) {
          let _date = this.date
          if (this.date.length === 2 && new Date(this.date[0]).getTime() > new Date(this.date[1]).getTime()) {
            _date = [this.date[1], this.date[0]]
          }
          return `${_date[0]} (${this.$Formatter.displayWeekday(_date[0])})${
            _date.length > 1 ? ` - ${_date[1]} (${this.$Formatter.displayWeekday(_date[1])})` : ''
          }`
        }
      } else if (this.isYearMonthOnly) {
        return this.date
      }

      return `${this.date} (${this.$Formatter.displayWeekday(this.date)})`
    },
  },
}
</script>
